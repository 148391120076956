import React, { PureComponent } from 'react';

class Footer extends PureComponent{

    render(props){      
        return(
        <footer> 
            <div className="copy">&copy; 2020 Mike Hunter</div>
        </footer>
        )
      }

      
}
export default Footer;