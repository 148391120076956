import React, { PureComponent } from 'react';
import data from '../api.json'; 
class About extends PureComponent{


    displayProgramming = () =>{
    return data.labels.programming.map((i,ind) => <li key={ind} data = {i} >{i}</li>);
    }

    displaySoftware = () =>{
        return data.labels.software.map((i,ind) => <li key={ind} data = {i} >{i}</li>);
    }

    displayTechnology = () =>{
        return data.labels.technology.map((i,ind) => <li key={ind} data = {i} >{i}</li>);
    }

     
    render(props){      
        return(
            <div>
            <section id="about">
                    <h4>About</h4>
                    <h1>
                       {data.labels.about_heading}
                    </h1>
                    <h4>
                       {data.labels.about_description}
                    </h4>
                </section>
                <section>
                    <div className="list-container">
                        <ul>
                            <li><strong>Programming</strong></li>
                            {this.displayProgramming()}
                        
                        </ul>
                        <ul>
                            <li><strong>Software</strong></li>
                            {this.displaySoftware()}
                        </ul>
                        <ul>
                            <li><strong>Technology</strong></li>
                            {this.displayTechnology()}
                        </ul>
                    </div>
                </section>
            </div>
        )
      }

      
}
export default About;