import React, { PureComponent } from 'react';
import data from '../api.json'; 



class Details extends PureComponent{



    goBack(){
        window.history.back(); 
    }
    renderContribution(contribution){

        let ret = contribution.map(function(itm, id){
        return(<li key={id}>{itm}</li>);
        })
        return ret; 
    }

    renderTechologies(technologies){

        let ret = technologies.map(function(itm, id){
        return(<li key={id}>{itm}</li>);
        })
        return ret; 

    }

    renderMedia(current, poster){
      let ret = current.map(function(itm, id){

            if(itm.type === "image"){

                return(<div key={id} className="grid-item">
                    <div><img alt={itm.title} src={itm.image} width="100%" /></div>
                </div>)

            } else {

               return( <div key={id} className="grid-item">
                <video poster={poster} muted controls>
                    <source src={itm.video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                </div>
               )

            }
       
       })

       return ret; 
    }
    render(props){    
        

        let current = data.nodes[this.props.index];
       

        return(
            <div>
                
            <section>
            <h4><div className="pointer" onClick={this.goBack} >&larr; Back</div></h4>
        <h1>
            {current.title} <span>{current.client}</span>
        </h1>
        <h4>
        {current.description}
        </h4>

        <div className="grid-container">
            {this.renderMedia(current.media, current.thumbnail)}
        </div>
    </section>

    <section>

        <div className="grid-container">
            <ul>
                <li><strong>Contribution</strong></li>
                {this.renderContribution(current.development)}
            </ul>

            <ul>
                <li><strong>Technologies</strong></li>
                {this.renderContribution(current.technology)}
            </ul>

            <ul>
                <li>&nbsp;</li>
                <li><strong>Produced By: </strong>{current.producer}</li>
               
            </ul>
        </div>



        

        

    </section>
    
    
   </div>
        )
      }

      
}
export default Details;