import React, { PureComponent } from 'react';
import {
    Link
  } from "react-router-dom";

class Item extends PureComponent{


    displayImage = () => {
        return this.props.data.thumbnail;
    }
    render(props){      
        return(
            <div className="grid-item">
                <Link to={"/works/" + this.props.data.slug}>
                <div><img alt={this.props.data.title} src={this.displayImage()} width="100%" /></div>
                <h2>{this.props.data.title}<span>{this.props.data.client}</span></h2>
                </Link>
            </div>
        )
      }

      
}
export default Item;