import React, { PureComponent } from 'react';
import data from "../api.json";
class Home extends PureComponent{

    render(props){      
        return(
            <section id="home">
                <h1>
                    {data.labels.home_heading}
                </h1>
                <h4>
                    {data.labels.home_description}
                </h4>
            </section>
        )
      }

      
}
export default Home;