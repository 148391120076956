import React, { PureComponent } from 'react';
import {Link} from "react-router-dom";
class Header extends PureComponent{

    render(props){    
        
       

        return(
            <header>
                <div className="logo pointer"><Link to="/"><img alt="Mike Hunter" src="/img/mikehunter.svg" /></Link></div>
                <div className="nav-icon pointer" onClick={this.props.openMenu}><img alt="Menu" src="/img/nav.svg" /></div>
            </header>
        )
      }

      
}
export default Header;