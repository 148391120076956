import React from "react";
import { BrowserRouter as Router, Switch, Route,useParams} from "react-router-dom";
import { useEffect, useState } from "react";

import './App.css';
import data from './api.json'; 
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import Content from './components/Content.js';

import Menu from "./components/Menu.js";


const App = () => {

  const [menuopen, setMenuOpen] = useState(false);
  const [details, setDetails] = useState(false);

  const openDetails = () => {
    setDetails(details => true);
  }

  const closeDetails = () => {
    setDetails(details => false);
  }

  const closeMenu = () => {
    setMenuOpen(menuopen => false);
  }

  const openMenu = () => {
    setMenuOpen(menuopen => true);
  }

  const wrapper = () =>{
    return (menuopen) ? "wrapperopened" : "wrapper";
  }
  return (
    
    <Router>
      
      <Menu menuopen={menuopen} closeMenu={closeMenu}  />
      <div className={wrapper()}>
      <Header menuopen={menuopen} openMenu={openMenu} />
        <Switch>
          <Route path="/about" children={<MainView path="about" />} />
          <Route path="/work" children={<MainView path="work" />} />
          <Route path="/contact" children={<MainView path="contact" />} />
          <Route path="/works/:id" children={<DetailsView details={details} closeMenu={closeDetails} openDetails={openDetails} />} />
          <Route path="/" children={<MainView path="top" />} />
        </Switch>
        <Footer />
        </div>
    </Router>
  );
}




const MainView = React.memo((props) => {

 

  useEffect(() => {

   
    
  
    switch(props.path){
      case "work":
        document.getElementById("work").scrollIntoView();
      break;
      case "about":
        document.getElementById("about").scrollIntoView();
      break;
      case "contact":
        document.getElementById("contact").scrollIntoView();
      break;
      default:
      break;
    }
  });
  return (
    <Content details={false} />
  );
});



const DetailsView = (props) => {
  let { id } = useParams();
  let index = 0;
  // Find
  for(let i = 0; i<data.nodes.length; i++){
    if(data.nodes[i].slug === id){
        index = i; 
        break; 
    }
  }

  // Move to top
  useEffect(() => {
    document.body.scrollTop = 0; 
    document.documentElement.scrollTop = 0;
  });
 
  return (
    <Content index={index} details={true} />
  );
}
  
export default App;