import React, { PureComponent } from 'react';
import {Link} from "react-router-dom";

class Menu extends PureComponent{

    render(props){      

        let isopen = (this.props.menuopen) ? "menu" : "menuclosed";
        return(
            <div id="menu" className={isopen}>
            <div className="close-icon pointer" onClick={this.props.closeMenu}><img alt="Close Menu" src="/img/close.svg" /></div>
            <ul>
                
                <li><Link onClick={this.props.closeMenu} to="/">Home</Link></li>
                <li><Link  to="/work" onClick={this.props.closeMenu}>Work</Link></li>
                <li><Link onClick={this.props.closeMenu} to="/about">About</Link></li>
                <li><Link onClick={this.props.closeMenu} to="/contact">Contact</Link></li>
            </ul>
        </div>
        )
      }

      
}
export default Menu;