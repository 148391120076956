import React, { PureComponent } from 'react';
import Item from './Item';
import data from '../api.json'; 


class Work extends PureComponent{


    

    displayList = () =>{
       return data.nodes.map(i => <Item id={i.ID} key={i.ID} data = {i} />);
    }
   
    render(props){      
        return(
            <section id="work">
                <h4>Work</h4>
                <div className="grid-container">
                {this.displayList()}
                </div>
            </section>
           
        )
      }

      
}
export default Work;