import React, { PureComponent } from 'react';
import data from "../api.json";
class Contact extends PureComponent{

    render(props){      
        return(
            <section id = "contact">
                <h4>Contact</h4>
                <h1>
                    {data.labels.contact_heading}
                </h1>
                <h4>
                    {data.labels.contact_description}
                </h4>
                <a href="mailto:mike@mikehunter.ca"><h2>Email: mike@mikehunter.ca</h2></a>
            </section>
        )
      }

      
}
export default Contact;