import React, { PureComponent } from 'react';
import Work from './Work.js';
import Home from './Home.js';
import About from './About.js';
import Contact from './Contact.js';
import Details from './Details.js';



class Content extends PureComponent{


  
  
  showDetails = () =>{

    if(this.props.details){
      return (<Details index={this.props.index} />)
    } else {

      return(

        
    
 


      <div>
       <Home />
       <hr />
       <Work />
       <hr />
       <About />
       <hr />
       <Contact />
      </div>

     
      );

    }

  }
  
    render(props){ 
      

      
      
      
        return(
        <div>{this.showDetails()}</div>
        )
      }
}
export default Content;